.Home {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column; }
  .Home .login_box {
    width: 200px;
    display: flex;
    flex-direction: column; }
    .Home .login_box input {
      padding: 10px;
      margin: 10px 0; }
