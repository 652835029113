/* //main hilight (a more fun one) - buttons, top tabs, pager, links
  #8D70FF
  */
.info {
  display: flex;
  flex-direction: column;
  font-family: 'InterRegular', system-ui,-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji';
  text-align: left; }
  .info .explainer_wrapper a {
    font-size: 14px; }
  .info .explainer_wrapper .anti_toggle {
    font-size: 13px;
    line-height: 13px;
    color: #697386;
    cursor: pointer;
    border-bottom: solid 1px #e3e8ee;
    width: calc(100% + 10px);
    margin-left: -10px;
    padding-left: 10px;
    padding-bottom: 6px;
    margin-top: -3px;
    transition: all;
    font-weight: 600; }
    .info .explainer_wrapper .anti_toggle:hover {
      color: black; }
  .info .explainer_wrapper .toggle {
    display: none;
    color: #697386;
    border: solid 1px #e3e8ee;
    border-radius: 16px;
    font-size: 13px;
    padding: 6px 10px;
    font-weight: 500;
    align-items: baseline;
    justify-content: space-between;
    line-height: 13px;
    cursor: pointer; }
    .info .explainer_wrapper .toggle:hover {
      background-color: #f8f9fa; }
    .info .explainer_wrapper .toggle.visible {
      display: inline-block; }
  .info .explainer_wrapper .description {
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI","Roboto","Helvetica Neue","Ubuntu";
    color: #6f7586;
    font-size: 14px;
    margin: 3px 0 10px 0; }
  .info .explainer_wrapper .type_link {
    text-decoration: underline;
    cursor: pointer; }
  .info .explainer_wrapper b {
    font-family: courier;
    font-size: 12px;
    font-weight: normal;
    background-color: #f5f8ff;
    border-radius: 3px;
    padding: 1px 5px;
    border: solid 1px #d1d5da;
    line-height: 0px;
    color: #6f7586; }
  .info .explainer_wrapper .name {
    font-family: "Menlo","Consolas"; }
    .info .explainer_wrapper .name .name_key {
      font-weight: bold;
      color: #2a2f45;
      font-size: 14px; }
    .info .explainer_wrapper .name .element_type, .info .explainer_wrapper .name .element_required {
      font-family: -apple-system,BlinkMacSystemFont,"Segoe UI","Roboto","Helvetica Neue","Ubuntu";
      font-size: 12px;
      color: #8792a2; }
    .info .explainer_wrapper .name .orange {
      font-size: 10px;
      color: #FF8319; }
    .info .explainer_wrapper .name .purple {
      color: #7155f1; }
    .info .explainer_wrapper .name .green {
      color: #0BCE21; }
    .info .explainer_wrapper .name .element_type {
      font-weight: 600;
      margin-left: 3px; }
  .info .explainer_wrapper .elements {
    padding: 10px;
    border: solid 1px #e3e8ee;
    border-radius: 16px;
    display: none;
    flex-direction: column; }
    .info .explainer_wrapper .elements.visible {
      display: flex; }
  .info .code_explainer .indent {
    margin: 5px 0 5px 10px; }
  .info .mobile_bars {
    background-color: white;
    padding: 0 10px;
    border-radius: 5px;
    border: solid 1px;
    display: none;
    position: absolute;
    right: 15px;
    top: 110px;
    cursor: pointer;
    font-family: 'InterRegular', system-ui,-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji';
    font-size: 16px; }
    @media all and (max-width: 930px) {
      .info .mobile_bars {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        height: 20px;
        border-bottom: solid 2px; }
        .info .mobile_bars i {
          padding-left: 10px; } }
    @media all and (max-width: 500px) {
      .info .mobile_bars {
        top: 70px; } }
  .info a {
    font-family: "InterRegular";
    color: #7155f1;
    font-weight: 400;
    font-size: 16px;
    line-height: 160%;
    letter-spacing: -0.011em; }
    .info a.small_link {
      font-size: 12px; }
  .info h1 {
    margin-bottom: 8px;
    font-family: 'InterRegular', system-ui,-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji';
    font-weight: 900;
    font-size: 1.9375rem;
    line-height: 1.2903226;
    letter-spacing: -0.021rem;
    color: #25292e; }
  .info h1, .info h2, .info h3 {
    word-break: break-word; }
    .info h1 i, .info h2 i, .info h3 i {
      vertical-align: middle;
      cursor: pointer;
      margin-left: 7px;
      font-size: 14px;
      background-color: #f8f9fa;
      color: #2e4145;
      border-radius: 10px;
      padding: 5px;
      border: solid 1px #d1d5da; }
      .info h1 i:hover, .info h2 i:hover, .info h3 i:hover {
        background-color: #d1d5da; }
  .info .small_title {
    font-size: 14px;
    font-weight: 500;
    font-family: 'InterSemiBold';
    text-transform: uppercase;
    margin-top: 20px; }
  .info .bullet_title {
    /* display: list-item;
    list-style-type:disc;  
    list-style-position:outside;
    margin-left: 15px; */
    font-size: 14px;
    font-weight: 500;
    font-family: 'InterSemiBold';
    margin: 20px 0 10px; }
  .info .list_bullet_title {
    /* display: list-item;
    list-style-type:disc;  
    list-style-position:outside;
    margin-left: 15px; */
    font-size: 14px;
    font-weight: 500;
    font-family: 'InterSemiBold';
    margin: 25px 0 0px; }
  .info .indent_title {
    font-weight: bold;
    font-family: 'InterSemiBold';
    font-size: 14px;
    margin: 20px 0 0px; }
  .info b {
    font-size: 14px;
    background-color: #f5f8ff;
    border-radius: 3px;
    padding: 2px 5px;
    border: solid 1px #d1d5da; }
  .info .types_code {
    margin-top: 5px; }
  .info .code {
    background-color: #f5f8ff;
    border-radius: 10px;
    border: solid 1px #d1d5da;
    font-family: 'Courier New', Courier, monospace;
    font-size: 13px;
    line-height: 16px;
    padding: 5px 10px;
    display: block;
    justify-content: space-between;
    align-items: center;
    width: calc(100% - 60px);
    word-break: keep-all;
    overflow-x: scroll;
    position: relative;
    color: #25292e; }
    .info .code .inner_code {
      width: 100%;
      overflow-x: scroll; }
      .info .code .inner_code .code_line {
        width: calc(100% + 100px);
        margin: 10px 0; }
    .info .code i {
      font-size: 16px;
      color: #7155f1;
      cursor: pointer;
      position: absolute;
      top: 5px;
      right: 10px; }
  .info h3 i {
    border-radius: 8px;
    margin-left: 7px;
    font-size: 11px;
    padding: 5px; }
  .info .doc_header {
    border-bottom: solid 1px #eeeff5;
    padding: 8px 10px 13px 30px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.18);
    z-index: 400;
    position: relative; }
    .info .doc_header .home_header .logo {
      padding: 0; }
      .info .doc_header .home_header .logo .logo_img {
        width: 180px;
        padding-right: 30px; }
        @media all and (max-width: 930px) {
          .info .doc_header .home_header .logo .logo_img {
            width: 33vw;
            height: 9vw; } }
        @media all and (max-width: 650px) {
          .info .doc_header .home_header .logo .logo_img {
            width: 33vw;
            height: 9vw; } }
        @media all and (max-width: 540px) {
          .info .doc_header .home_header .logo .logo_img {
            width: 35vw;
            height: 9vw; } }
        @media all and (max-width: 490px) {
          .info .doc_header .home_header .logo .logo_img {
            width: 150px;
            height: 9vw; } }
        @media all and (max-width: 375px) {
          .info .doc_header .home_header .logo .logo_img {
            width: 160px;
            height: 10vw; } }
        @media all and (max-width: 350px) {
          .info .doc_header .home_header .logo .logo_img {
            width: 170px;
            height: 11vw; } }
        @media all and (max-width: 325px) {
          .info .doc_header .home_header .logo .logo_img {
            width: 155px;
            height: 11vw; } }
        @media all and (max-width: 305px) {
          .info .doc_header .home_header .logo .logo_img {
            width: 150px;
            height: 11vw; } }
        @media all and (max-width: 290px) {
          .info .doc_header .home_header .logo .logo_img {
            width: 140px;
            height: 11vw; } }
        @media all and (max-width: 275px) {
          .info .doc_header .home_header .logo .logo_img {
            width: 130px;
            height: 11vw; } }
  .info h2 {
    font-family: 'InterSemiBold';
    color: #25292e;
    font-size: 48px;
    line-height: 120%;
    letter-spacing: -0.022em;
    font-weight: 500;
    margin-top: 3rem;
    margin-bottom: 1rem;
    padding-bottom: 0.5rem;
    padding-top: 1rem;
    border-bottom: 1px solid #e1e4e8; }
    @media all and (max-width: 930px) {
      .info h2 {
        font-size: 36px; } }
  .info h3 {
    font-family: 'InterSemiBold',system-ui,-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji';
    font-weight: 500;
    font-size: 1.25rem;
    line-height: 1.5;
    letter-spacing: -0.017rem;
    color: #25292e;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    padding-top: 1rem; }
    .info h3 .pro-ico {
      margin-left: 6px; }
  .info .documentation {
    display: flex;
    flex-direction: row;
    height: calc(100vh - 60px);
    /*  ::-webkit-scrollbar {
      width: 6px;
    } */
    /*  ::-webkit-scrollbar-track {
      background: white;
    }
 */
    /* ::-webkit-scrollbar-thumb {
      background: #ccc;
      border-radius: 20px;
    }

    
    ::-webkit-scrollbar-thumb:hover {
      background: $grackle_green;
    }  */ }
    .info .documentation .left_panel {
      width: 16%;
      height: calc(100vh - 140px);
      overflow-y: auto;
      scroll-behavior: smooth;
      padding: 40px 40px 40px;
      display: flex;
      flex-direction: column;
      border-right: solid 1px #eeeff5; }
      .info .documentation .left_panel .main_nav_content {
        padding-top: 40px; }
      .info .documentation .left_panel .doc_tabs {
        display: flex;
        flex-direction: row;
        margin: -40px -40px 0 -40px;
        padding: 0 0 0px 0;
        border-bottom: solid 1px #e1e4e8;
        justify-content: space-evenly; }
        .info .documentation .left_panel .doc_tabs .doc_tab {
          display: flex;
          flex: 1;
          justify-content: center;
          cursor: pointer;
          padding: 10px 0px;
          color: #25292e;
          font-family: 'InterSemiBold',system-ui,-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji'; }
          .info .documentation .left_panel .doc_tabs .doc_tab.selected {
            background-color: #f0f1f2; }
            .info .documentation .left_panel .doc_tabs .doc_tab.selected:hover {
              background-color: #f0f1f2; }
          .info .documentation .left_panel .doc_tabs .doc_tab:hover {
            background-color: #f8f9fa; }
      .info .documentation .left_panel .mobile_menu_x {
        display: none; }
      @media all and (max-width: 930px) {
        .info .documentation .left_panel {
          display: none; }
          .info .documentation .left_panel.open {
            display: flex;
            position: absolute;
            width: calc(100% - 84px);
            background-color: white;
            z-index: 10; }
            .info .documentation .left_panel.open .mobile_menu_x {
              display: flex;
              justify-content: flex-start;
              font-size: 20px; } }
      .info .documentation .left_panel .sub_menu {
        display: flex;
        flex-direction: column;
        margin-bottom: 10px; }
      .info .documentation .left_panel .link {
        display: block;
        text-decoration: none;
        font-family: "InterRegular";
        font-weight: 400;
        letter-spacing: -0.011em;
        color: #25292e;
        line-height: 140%;
        -webkit-transition: 50ms ease color;
        transition: 50ms ease color;
        font-size: 15px;
        margin-bottom: 12px; }
        .info .documentation .left_panel .link.hilighted {
          color: #7155f1;
          display: list-item;
          /* This has to be "list-item"                                               */
          list-style-type: disc;
          /* See https://developer.mozilla.org/en-US/docs/Web/CSS/list-style-type     */
          list-style-position: outside; }
      .info .documentation .left_panel .title {
        margin: 0;
        padding: 0;
        color: #25292e;
        font-weight: 400;
        line-height: 160%;
        letter-spacing: -0.011em;
        font-size: 15px;
        display: block;
        position: relative;
        margin-bottom: 12px;
        text-decoration: none;
        font-family: "InterMedium";
        border-bottom: 1px solid #e1e4e8;
        padding-bottom: 0.25rem;
        box-sizing: border-box;
        vertical-align: baseline;
        cursor: pointer; }
    .info .documentation .main_panel {
      width: 60%;
      height: calc(100vh - 140px);
      overflow-y: scroll;
      margin: 0 20px;
      padding: 40px 56px;
      display: flex;
      flex-direction: column; }
      @media all and (max-width: 930px) {
        .info .documentation .main_panel {
          width: 80%; } }
      @media all and (max-width: 650px) {
        .info .documentation .main_panel {
          padding: 40px 20px;
          width: calc(100% - 40px); } }
      .info .documentation .main_panel .diagram_image_wrapper {
        background-color: #f5f8ff;
        border-radius: 10px;
        border: solid 1px #d1d5da; }
      .info .documentation .main_panel img.more_image {
        width: 70px;
        padding: 3px 5px;
        margin: 0 5px -10px;
        background-color: white; }
      .info .documentation .main_panel img.connecting, .info .documentation .main_panel img.connection_checkboxes, .info .documentation .main_panel img.browser_store_settings {
        height: 200px; }
        @media all and (max-width: 470px) {
          .info .documentation .main_panel img.connecting, .info .documentation .main_panel img.connection_checkboxes, .info .documentation .main_panel img.browser_store_settings {
            height: auto;
            width: calc(100% - 20px);
            display: flex;
            align-items: center;
            align-self: center;
            justify-content: center;
            margin: auto; } }
      .info .documentation .main_panel img.grackle_host_download {
        height: 300px;
        padding-bottom: 20px; }
        @media all and (max-width: 470px) {
          .info .documentation .main_panel img.grackle_host_download {
            display: flex;
            align-items: center;
            align-self: center;
            justify-content: center;
            margin: auto; } }
        @media all and (max-width: 300px) {
          .info .documentation .main_panel img.grackle_host_download {
            width: calc(100% - 0px);
            height: auto;
            display: flex;
            align-items: center;
            align-self: center;
            justify-content: center;
            margin: auto; } }
      .info .documentation .main_panel img.create_view_1, .info .documentation .main_panel img.create_view_2, .info .documentation .main_panel img.create_view_3, .info .documentation .main_panel img.team_share {
        height: 240px; }
        @media all and (max-width: 470px) {
          .info .documentation .main_panel img.create_view_1, .info .documentation .main_panel img.create_view_2, .info .documentation .main_panel img.create_view_3, .info .documentation .main_panel img.team_share {
            height: auto;
            width: calc(100% - 20px);
            display: flex;
            align-items: center;
            align-self: center;
            justify-content: center;
            margin: auto; } }
      .info .documentation .main_panel img.filter_by_column, .info .documentation .main_panel img.sort_by_column {
        width: 100%; }
      .info .documentation .main_panel img.filters_1 {
        height: 260px; }
        @media all and (max-width: 470px) {
          .info .documentation .main_panel img.filters_1 {
            display: flex;
            align-items: center;
            align-self: center;
            justify-content: center;
            margin: auto; } }
        @media all and (max-width: 300px) {
          .info .documentation .main_panel img.filters_1 {
            width: calc(100% - 0px);
            height: auto;
            display: flex;
            align-items: center;
            align-self: center;
            justify-content: center;
            margin: auto; } }
      .info .documentation .main_panel img.edit_columns, .info .documentation .main_panel img.limit_and_pagination, .info .documentation .main_panel img.create_sorting {
        height: 300px; }
        @media all and (max-width: 470px) {
          .info .documentation .main_panel img.edit_columns, .info .documentation .main_panel img.limit_and_pagination, .info .documentation .main_panel img.create_sorting {
            height: auto;
            width: calc(100% - 20px);
            display: flex;
            align-items: center;
            align-self: center;
            justify-content: center;
            margin: auto; } }
      .info .documentation .main_panel .text {
        padding: 0 0px 10px;
        font-family: "InterRegular";
        color: #4f566b;
        font-weight: 400;
        font-size: 16px;
        line-height: 160%;
        letter-spacing: -0.011em;
        display: block; }
      .info .documentation .main_panel .image_wrapper img {
        border-radius: 10px;
        border: solid 1px #d1d5da; }
      .info .documentation .main_panel .diagram_image_wrapper {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        /*  &.g_db_store {
          height: 350px;
        }

        &.browser_store_grackle_server_retrieval {
          height: 350px;
        }

        &.g_host_internal_store {
          height: 365px;
        }

        &.g_host_browser_store {
          height: 365px;
        } */ }
        .info .documentation .main_panel .diagram_image_wrapper .diagram_image {
          display: flex;
          align-self: center; }
        .info .documentation .main_panel .diagram_image_wrapper.g_host_internal_store, .info .documentation .main_panel .diagram_image_wrapper.g_host_browser_store, .info .documentation .main_panel .diagram_image_wrapper.g_db_store, .info .documentation .main_panel .diagram_image_wrapper.browser_store_grackle_server_retrieval {
          margin: 0px 0 40px;
          overflow: hidden;
          align-items: flex-end;
          display: flex; }
          .info .documentation .main_panel .diagram_image_wrapper.g_host_internal_store img, .info .documentation .main_panel .diagram_image_wrapper.g_host_browser_store img, .info .documentation .main_panel .diagram_image_wrapper.g_db_store img, .info .documentation .main_panel .diagram_image_wrapper.browser_store_grackle_server_retrieval img {
            width: calc(100% - 40px);
            padding: 20px; }
          .info .documentation .main_panel .diagram_image_wrapper.g_host_internal_store .diagram_image, .info .documentation .main_panel .diagram_image_wrapper.g_host_browser_store .diagram_image, .info .documentation .main_panel .diagram_image_wrapper.g_db_store .diagram_image, .info .documentation .main_panel .diagram_image_wrapper.browser_store_grackle_server_retrieval .diagram_image {
            align-self: flex-end;
            padding-top: 20px; }
  .info.libraries .mobile_bars {
    left: 15px;
    right: unset;
    top: 120px; }
    @media all and (max-width: 900px) {
      .info.libraries .mobile_bars {
        top: 100px; } }
    @media all and (max-width: 700px) {
      .info.libraries .mobile_bars {
        left: unset;
        right: 15px;
        top: 100px; } }
    @media all and (max-width: 500px) {
      .info.libraries .mobile_bars {
        top: 70px; } }
  @media all and (max-width: 414px) {
    .info.libraries .code {
      width: calc(100% - 30px); } }
  .info.libraries .main_panel {
    width: 45%;
    margin: 0 0px 0 20px;
    padding: 40px 40px 40px 20px; }
    @media all and (max-width: 930px) {
      .info.libraries .main_panel {
        width: 60%; } }
    @media all and (max-width: 700px) {
      .info.libraries .main_panel {
        width: 100%; } }
    .info.libraries .main_panel .indent {
      margin: 7px 0 7px 10px; }
    .info.libraries .main_panel a {
      text-decoration: none; }
      .info.libraries .main_panel a b {
        text-decoration: none; }
    .info.libraries .main_panel b {
      color: #2a2f45;
      font-family: 'Courier New', Courier, monospace;
      font-weight: normal; }
    .info.libraries .main_panel .text ul {
      font-size: 14px;
      padding: 5px 0 5px 25px; }
      .info.libraries .main_panel .text ul:first-child {
        padding-left: 15px; }
      .info.libraries .main_panel .text ul li {
        padding: 0px 0 5px 0;
        line-height: 22px; }
  .info.libraries .right_panel {
    width: 26%;
    padding: 20px;
    border-left: solid 1px #eeeff5;
    box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.18);
    overflow-y: scroll; }
    @media all and (max-width: 930px) {
      .info.libraries .right_panel {
        width: 40%; } }
    @media all and (max-width: 700px) {
      .info.libraries .right_panel {
        display: none; } }
    .info.libraries .right_panel .right_explainer .code {
      padding: 10px;
      font-size: 12px;
      line-height: 16px; }
      .info.libraries .right_panel .right_explainer .code .indent {
        margin: 5px 0 5px 10px; }
    .info.libraries .right_panel .example_description {
      display: none; }
  .info.libraries .library_example {
    margin: 20px 0; }
  .info.libraries .right_explainer .code {
    padding: 20px;
    background-color: #4f566b;
    color: #0bea6a;
    font-family: "Menlo","Consolas"; }
    .info.libraries .right_explainer .code .inner_code {
      width: 100%;
      overflow-x: scroll;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start; }
      .info.libraries .right_explainer .code .inner_code .code_line {
        width: calc(100% + 100px);
        margin: 10px 0; }
  .info.libraries .right_explainer .title {
    font-family: 'InterSemiBold',system-ui,-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji';
    border-bottom: solid 1px #f0f1f2;
    width: 100%;
    margin-bottom: 10px;
    justify-content: space-between;
    display: flex;
    align-items: center; }
    .info.libraries .right_explainer .title i {
      color: #7155f1;
      cursor: pointer; }
